.callout {
    padding: 0.8em 1em;
    line-height: 1.2;
    text-align: left;
    position: relative;
    clear: both;
    border-radius: 10px 10px;
}

.callout::before {
  width: 35px;
  height: 35px;
  display: inline-flex;
  justify-content: center;
  position: absolute;
  font-size: 1.5em;
  left: -1.2rem;
  background-color: #b4aaff;
  border-radius: 50%;
  align-items: center;
  top: -0.8rem;
}

.callout.warning {
  background-color: #ffe5d9;
}

.callout.success {
  background-color: #edf3ed;
}

.callout.success::before {
  content: "🎉";
  background-color: #3cc3c3;
  border-radius: 50%;
  align-items: center;
  top: -0.8rem;
}

li {
  margin: 10px 0;
}