html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  line-height: 1.6;
  font-size: 16px;
}

* {
    box-sizing: border-box;
}

.btn {
    appearance: none;
    -webkit-appearance: none;
    cursor: pointer;
    padding:10px;
    border:none;
    background-color:#3F51B5;
    color:#fff;
    font-weight:600;
    border-radius:5px;
    width:100%;
}

input, select, textarea {
    padding:10px;
    margin: 10px 0;
    border: 0;
    border-bottom: 1px solid #eee;
    box-shadow:0 0 15px 4px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    width: 100%;
}

img {
    width: 100%;
}
